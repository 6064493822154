import React, { useState, useEffect } from "react";
import style from "./Countdown.module.css";

const Countdown = () => {

    const [time, setTime] = useState(new Date());
    const endDate = new Date("2021-06-26T11:00:00");

    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            if (now.getTime() < endDate.getTime()) {
                setTime(now);
            } else {
                setTime(endDate);
                clearInterval(timer);
            }
            setTime(new Date());
        }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, [])

    const distance = endDate.getTime() - time.getTime();
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance < 0) {
        return <div/>
    }
    else return (
        <div className={style.countdown}>
            <span className={style.number}>{days} TAGE</span>
            <span className={style.number}>{hours} STUNDEN</span>
            <span className={style.number}>{minutes} MINUTEN</span>
            <span className={style.number}>{seconds} SEKUNDEN</span>
        </div>
    )
}

export default Countdown