import React from "react";
import style from "./App.module.css";
import Locations from "../Locations/Locations";
import HeadImage from "../HeadImage/HeadImage";
import Countdown from "../Countdown/Countdown";

const App = () => {


    const unterkunftText1 = "Direkt auf dem Rittergut gibt es leider keine Übernachtungsmöglichkeiten. Wenn Ihr noch eine Unterkunft sucht, dann schaut doch mal im "
    const unterkunftText2 = " nach. Gerne könnt ihr euch auch direkt bei uns melden. Wir vermitteln auch einzelne Zimmer bei Freunden aus der Umgebung sofern es erlaubt ist."

    const newsText3 = "Die aktuellen Zahlen für Kassel stehen "
    const newsText4 = "Die aktuellen Auflagen für Hessen stehen "

    const kontakteText = "Wer noch etwas mitbringen möchte meldet sich bitte bei Brigitte Flämig: brima.flaemig@gmx.de"

    const link = "https://www.immenhausen.de/verzeichnis/index.php?mandatstyp=8";
    const linkCorona = "https://www.kassel.de/aktuelles/aktuelle-meldungen/coronavirus.php";
    const linkCorona2 = "https://www.kassel.de/aktuelles/aktuelle-meldungen/frage-und-antwort-katalog.php#-C396ffnungsstufen_und_Eskalationskonzept";

    return (
        <div className={style.App}>
            <HeadImage />
            <Countdown />
            <div className={style.news}>
                <span className={style.heading}>Die Fotos sind da!</span>
                <span className={style.newsLine}>Über folgenden Dropbox-Link könnt ihr euch die Fotos ansehen: </span>
                <a target="_blank" href={"https://www.dropbox.com/sh/1pu9uudj7mcabsd/AAADpGHGqKIIyoJ1GqO2jr0na?dl=0"}>zur Dropbox</a>
                <br/>
                <span className={style.newsLine}>Wenn ihr noch Fotos habt, die ihr teilen wollt, dann meldet euch einfach bei uns.</span>
            </div>
            <div className={style.img} />
        </div>
    )
}

export default App;