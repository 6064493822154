import React from "react";
import style from "./HeadImage.module.css";

const HeadImage = props => {
    return (
        <div className={style.img}>
            <div className={style.content}>
                <span className={style.title}>Vielen Dank!</span>
                <span className={style.date}>26.06.2021</span>
            </div>
        </div>
    )
}

export default HeadImage;